// Luxon settings: throw exceptions if objects are invalid
import {Settings} from "luxon";
import {overrideTestMode, useAdminStore} from "app/state/admin";

Settings.throwOnInvalid = true;

import("./sentry")
  .then(() => {
    window.__fstDebug = (testMode: boolean) => {
      const currentTestMode = useAdminStore.getState().testMode;
      overrideTestMode(testMode);
      const falseStyle = "color: darkgreen; font-weight: bold;";
      const trueStyle = "color: darkred; font-weight: bold;";
      const currentStyle = currentTestMode ? trueStyle : falseStyle;
      const newStyle = testMode ? trueStyle : falseStyle;
      console.info("Changing test mode from %c%s %cto %c%s", currentStyle, currentTestMode, "", newStyle, testMode);
    };

    if (location.pathname.indexOf("/employees") === 0) {
      return import("./employee/app");
    } else {
      return import("./app");
    }
  })
  .then((app) => app.default());
