import {createWithEqualityFn as zustandCreate} from "zustand/traditional";
import {immer} from "zustand/middleware/immer";

const initialState = {
  testMode: import.meta.env.VITE_TEST_MODE === "true",
  features: {
    showVideoStats: false,
    allowSeeking: false,
    disableChapters: false,
    highlightAnswers: false,
    testSendEnabled: false,
    controlCardExtras: false
  }
};

export const useAdminStore = zustandCreate(
  immer(() => initialState),
  Object.is
);

export const overrideTestMode = (testMode: boolean) => {
  if (testMode) {
    useAdminStore.setState(
      {
        testMode,
        features: {
          showVideoStats: false,
          allowSeeking: false,
          disableChapters: false,
          highlightAnswers: false,
          testSendEnabled: false,
          controlCardExtras: true
        }
      },
      true
    );
  } else {
    useAdminStore.setState(
      {
        ...initialState,
        testMode
      },
      true
    );
  }
};

export const toggleFeature = (feature: keyof typeof initialState.features) => {
  useAdminStore.setState((state) => {
    state.features[feature] = !state.features[feature];
  });
};
